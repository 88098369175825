import classNames from 'classnames';
import React, { ComponentPropsWithoutRef } from 'react';

const Quotes = (props: ComponentPropsWithoutRef<'svg'>) => {
  return (
    <svg
      viewBox="0 0 42 32"
      version="1.1"
      {...props}
      className={classNames(
        props.className,
        'w-8 md:w-10 h-6 md:h-8 fill-current text-teal-500'
      )}
    >
      <g stroke="none" strokeWidth="1" fillRule="evenodd">
        <g transform="translate(-382.000000, -4299.000000)" fillRule="nonzero">
          <path d="M414.91018,4331 C417.209581,4331 419.165669,4330.18563 420.778443,4328.55689 C422.391218,4326.92814 423.197605,4325.04391 423.197605,4322.90419 C423.197605,4320.86028 422.49501,4319.09581 421.08982,4317.61078 C419.684631,4316.12575 418.071856,4315.38323 416.251497,4315.38323 C414.686627,4315.38323 413.393214,4315.7505 412.371257,4316.48503 C412.051896,4316.70858 411.812375,4316.82036 411.652695,4316.82036 C411.493014,4316.82036 411.349301,4316.72455 411.221557,4316.53293 C411.02994,4316.24551 410.934132,4315.7984 410.934132,4315.19162 C410.934132,4312.38124 411.876248,4309.67465 413.760479,4307.07186 C415.644711,4304.46906 418.247505,4302.56088 421.568862,4301.34731 L421.568862,4301.34731 L421.568862,4299 C415.852295,4301.07585 411.668663,4303.82236 409.017964,4307.23952 C406.367265,4310.65669 405.041916,4314.52096 405.041916,4318.83234 C405.041916,4322.53693 406.015968,4325.49102 407.964072,4327.69461 C409.912176,4329.8982 412.227545,4331 414.91018,4331 Z M391.868263,4331 C394.167665,4331 396.123752,4330.18563 397.736527,4328.55689 C399.349301,4326.92814 400.155689,4325.04391 400.155689,4322.90419 C400.155689,4320.86028 399.453094,4319.09581 398.047904,4317.61078 C396.642715,4316.12575 395.02994,4315.38323 393.209581,4315.38323 C391.644711,4315.38323 390.351297,4315.7505 389.329341,4316.48503 C389.00998,4316.70858 388.770459,4316.82036 388.610778,4316.82036 C388.451098,4316.82036 388.307385,4316.72455 388.179641,4316.53293 C387.988024,4316.24551 387.892216,4315.7984 387.892216,4315.19162 C387.892216,4312.38124 388.834331,4309.67465 390.718563,4307.07186 C392.602794,4304.46906 395.205589,4302.56088 398.526946,4301.34731 L398.526946,4301.34731 L398.526946,4299 C392.810379,4301.07585 388.626747,4303.82236 385.976048,4307.23952 C383.325349,4310.65669 382,4314.52096 382,4318.83234 C382,4322.53693 382.974052,4325.49102 384.922156,4327.69461 C386.870259,4329.8982 389.185629,4331 391.868263,4331 Z" />
        </g>
      </g>
    </svg>
  );
};

export default Quotes;
