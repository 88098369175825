import classNames from 'classnames';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import Quotes from './Quotes';

const Testimonial = ({
  testimonial,
  className,
}: {
  className?: string;
  testimonial: {
    testimonial: string;
    name: string;
    title: string;
    image?: string;
  };
}) => {
  const data = useStaticQuery(graphql`
    query {
      testimonialImages: allFile(
        filter: {
          internal: { mediaType: { regex: "/image/" } }
          relativeDirectory: { eq: "testimonials" }
        }
      ) {
        edges {
          node {
            relativePath
            extension
            publicURL
            childImageSharp {
              gatsbyImageData(width: 80, height: 80, placeholder: NONE)
            }
          }
        }
      }
    }
  `);

  const testimonialImages = data.testimonialImages.edges.map((edge) => ({
    ...edge.node,
    name: edge.node.relativePath.split('/').pop(),
  }));

  return (
    <div
      className={classNames(
        'grid grid-cols-8 gap-x-4 gap-y-2 my-10',
        className
      )}
    >
      <div>
        <Quotes />
      </div>
      <div className="col-span-8 md:col-span-7">
        <p className="text-base md:text-lg lg:text-xl">
          {testimonial.testimonial}
        </p>
        <div className="flex items-center mt-6">
          <div>
            <div className="rounded-full overflow-hidden bg-teal-500 w-20 h-20 mr-4 flex-grow-0 flex-shrink-0">
              {testimonial.image &&
                testimonialImages.find((i) => i.name === testimonial.image) && (
                  <GatsbyImage
                    image={getImage(
                      testimonialImages.find(
                        (i) => i.name === testimonial.image
                      )!.childImageSharp
                    )}
                    alt=""
                    imgClassName="rounded-full overflow-hidden"
                  />
                )}
            </div>
          </div>
          <div>
            <p className="text-lg md:text-xl font-semibold">
              {testimonial.name}
            </p>
            <p className="text-xs md:text-sm">{testimonial.title}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
