import * as React from 'react';

import {
  ArchiveIcon,
  CashIcon,
  CurrencyEuroIcon,
  DocumentDuplicateIcon,
  TruckIcon,
  UserGroupIcon,
} from '@heroicons/react/outline';
import { StaticImage } from 'gatsby-plugin-image';
import RoundBox from '../components/atoms/RoundBox';
import CTA, { CTAButton } from '../components/CTA';
import ShowCase from '../components/atoms/ShowCase';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import {
  BadgeCheckIcon,
  ShieldCheckIcon,
  KeyIcon,
} from '@heroicons/react/outline';
import { Link } from 'gatsby';
import Testimonial from '../components/atoms/Testimonial';

const featureList = [
  { icon: DocumentDuplicateIcon, sub: 'Tilbud, ordrer og faktura' },
  { icon: UserGroupIcon, sub: 'Bedre overblik over kunder' },
  { icon: TruckIcon, sub: 'Indkøb hos leverandør' },
  { icon: CurrencyEuroIcon, sub: 'Brug flere valuta' },
  { icon: ArchiveIcon, sub: 'Lagerstyring' },
  { icon: CashIcon, sub: 'Automatisk prissætning' },
];

export const testimonials = [
  {
    testimonial:
      'Vi kiggede på mange forskellige CRM og salgssystemer, inklusiv WebCRM, Uniconta og SimplyCRM. Vi valgte Sails fordi det fokusere på selve salget, er meget brugervenlig og ødelægger ikke vores budget.',
    name: 'Paw Meng Mortensen',
    title: 'Direktør og ejer – Artwork Systems Nordic A/S',
    image: 'paw.jpg',
  },
  /*{
    testimonial:
      'Vi kiggede på mange forskellige CRM og salgssystemer, inklusiv WebCRM, Uniconta og SimplyCRM. Vi valgte Sails fordi det fokusere på selve salget, er meget brugervenlig og ødelægger ikke vores budget.',
    name: 'Paw Meng Mortensen',
    title: 'Direktør og ejer – Artwork Systems Nordic A/S',
    image: 'paw.jpg',
  },*/
];

const IndexPage = () => {
  return (
    <Layout headerClass="bg-white">
      <SEO title="Et brugervenligt salgssystem" />
      <div className="container mt-20 py-16 relative z-10">
        <h1 className="heading text-center max-w-4xl mx-auto">
          Et <span className="text-indigo-600">bruger&shy;venligt</span>{' '}
          salgs&shy;system
        </h1>
        <div className="max-w-xl mx-auto ">
          <h3 className="sub text-center mt-8">
            Lav lynhurtigt nye tilbud, modtag ordrer og send faktura til dine
            kunder. Sails snakker sammen med dit økonomisystem og automatiserer
            bogføringen.
          </h3>

          <div className="flex flex-wrap items-start justify-center mt-8">
            <CTAButton className="mx-2 mt-4" />
            <Link to="/contact">
              <div className="btn-secondary mx-2 mt-4">Snak med os</div>
            </Link>
          </div>
        </div>
      </div>
      <div className="py-0 md:py-16 relative" style={{ zIndex: -1 }}>
        <svg
          className="w-full absolute -translate-y-1/4 top-12 md:top-24 transform"
          viewBox="0 0 1440 793"
          version="1.1"
        >
          <g
            id="Home"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g id="Desktop-HD" transform="translate(0.000000, -624.000000)">
              <g id="Triangles" transform="translate(-0.000000, 624.711712)">
                <polygon
                  id="Rectangle"
                  fill="#22D3EE"
                  points="429 327.288288 1439.87814 0 1439.87814 654"
                />
                <polygon
                  id="Rectangle"
                  fill="#22D3EE"
                  transform="translate(505.439072, 327.000000) scale(-1, 1) translate(-505.439072, -327.000000) "
                  points="-1.13686838e-13 327.288288 1010.87814 0 1010.87814 654"
                />
                <polygon
                  id="Rectangle"
                  fill="#06B6D4"
                  points="429 465.288288 1439.87814 138 1439.87814 792"
                />
                <polygon
                  id="Rectangle"
                  fill="#06B6D4"
                  transform="translate(505.439072, 465.000000) scale(-1, 1) translate(-505.439072, -465.000000) "
                  points="-1.13686838e-13 465.288288 1010.87814 138 1010.87814 792"
                />
              </g>
            </g>
          </g>
        </svg>
        <div className="w-full flex justify-center px-8">
          <ShowCase />
        </div>
      </div>

      <div className="container relative z-10 my-20 md:my-44">
        <h3 className="font-semibold text-3xl text-center mb-8">
          Allerede i brug i flere successfulde handelsvirksomheder
        </h3>

        <div className="flex items-center justify-between max-w-2xl mx-auto">
          {[1, 2, 3, 4].map((i) => (
            <StaticImage
              key={i}
              src="../images/acme-logo.png"
              width={116}
              backgroundColor="transparent"
              alt=""
              placeholder="blurred"
            />
          ))}
        </div>
      </div>

      <div className="container my-20 md:my-44">
        <div className="max-w-3xl mx-auto">
          <h2 className="heading-md">
            Sikrer et{' '}
            <span className="text-light-blue-600">let og hurtigt salg</span>{' '}
            uden besvær
          </h2>
          <p className="sub mt-8">
            Lav lynhurtigt nye tilbud, modtag ordrer og send faktura til dine
            kunder. Sails snakker sammen med dit økonomisystem og automatiserer
            bogføringen.
          </p>
        </div>

        <div className="max-w-5xl mx-auto grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-20">
          {featureList.map((f, i) => (
            <div key={i} className="flex items-center">
              <RoundBox className="flex-shrink-0 mr-4">
                <f.icon className="w-10 h-10 text-light-blue-500" />
              </RoundBox>
              <p className="sub font-semibold">{f.sub}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="container my-20 md:my-44">
        <div className="max-w-3xl mx-auto">
          <h2 className="heading-md">
            Integrerer med dit{' '}
            <span className="text-green-500">økonomi&shy;system</span>
          </h2>
          <p className="sub mt-8">
            Dit salgsarbejde skal ikke dikteres af dit økonomi&shy;systems
            stringente regler. Sails fjerner stridig&shy;hederne mellem dit
            økonomi&shy;system og dine arbejdsgange. Sails sikrer, at alle dine
            salg og indkøb bogføres helt automatisk.
          </p>
        </div>

        <div className="mt-16 max-w-5xl mx-auto">
          <StaticImage
            src="../images/integrations-lg.png"
            alt="Sails integrerer med e-conomic"
            layout="fullWidth"
            placeholder="blurred"
          />
        </div>

        <div className="max-w-3xl mx-auto mt-16">
          <h3 className="font-semibold text-2xl lg:text-3xl mb-4">
            Er du klar til at komme igang?
          </h3>
          <div className="block md:flex justify-between">
            <p className="sub">
              Nå et helt nyt niveau i dit salgsarbejde og opnå bedre resultater
            </p>
            <div className="inline-block">
              <CTAButton className="mt-4 md:mt-0 md:ml-8" />
            </div>
          </div>
        </div>
      </div>

      <div className="container my-20 md:my-44">
        <div className="max-w-3xl mx-auto">
          <h2 className="heading-md mb-10 md:mb-20">
            Du er i <span className="text-teal-500">godt selskab</span>
          </h2>

          {testimonials.map((testimonial, i) => (
            <Testimonial key={i} className="my-10" testimonial={testimonial} />
          ))}
        </div>
      </div>

      <div className="bg-blue-100 pt-16 pb-24 md:pt-36 md:pb-44">
        <div className="container">
          <div className="max-w-3xl mx-auto">
            <StaticImage
              src="../images/eu-flag.png"
              alt="EU flag"
              layout="fixed"
              placeholder="blurred"
              width={100}
              imgClassName="rounded-md overflow-hidden"
              className="rounded-md overflow-hidden mx-auto mb-8"
            />
            <h2 className="heading-md mb-10 md:mb-20 text-blue-900 text-center">
              Høj sikkerhed, bygget i <span className="text-blue-600">EU</span>
            </h2>
          </div>
          <div className="max-w-5xl mx-auto">
            <div className="flex justify-between text-blue-900">
              <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 text-center">
                <div>
                  <BadgeCheckIcon className="h-16 w-16 text-blue-500 mb-2 mx-auto" />
                  <p className="font-semibold text-2xl">ISO certificeret</p>
                  <p className="text-lg">
                    Alle Sails' servere er ISO-certificeret og fysisk placeret i
                    EU. Backups er placeret på forskellige lokationer.
                  </p>
                </div>
                <div>
                  <ShieldCheckIcon className="h-16 w-16 text-blue-500 mb-2 mx-auto" />
                  <p className="font-semibold text-2xl">SSL krypteret</p>
                  <p className="text-lg">
                    Vi reviderer løbende vores kodebase og anvender
                    SSL-kryptering for at sikre din data bedst muligt.
                  </p>
                </div>
                <div>
                  <KeyIcon className="h-16 w-16 text-blue-500 mb-2 mx-auto" />
                  <p className="font-semibold text-2xl">
                    Streng adgangspolitik
                  </p>
                  <p className="text-lg">
                    Med en streng adgangskode og rolle-baseret adgang er det kun
                    dem du giver lov der kan få adgang.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CTA />
    </Layout>
  );
};

export default IndexPage;
