import React, {useCallback, useEffect, useRef} from 'react';
import {StaticImage} from 'gatsby-plugin-image';
import {animated, useSpring} from 'react-spring';

export default function ShowCase() {
  const containerRef = useRef<HTMLDivElement>(null);
  const [{transform}, set] = useSpring(() => ({
    transform: `rotateX(7.5deg) scale(0.75)`,
  }));

  const onScroll = useCallback(() => {
    // Handle scroll event
    const rect = containerRef.current?.getBoundingClientRect();
    const offset = rect.y - window.innerHeight / 2 + rect.height / 2;
    const position = Math.max(
      0,
      Math.min(1, offset / (window.innerHeight * (1 / 2)))
    );
    set({
      transform: `rotateX(${position * 7.5}deg) scale(${
        (1 - position) / 4 + 0.75
      })`,
    });
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', onScroll, {passive: true});
    onScroll();
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <div
      ref={containerRef}
      style={{
        position: 'relative',
        perspective: '1000px',
        zIndex: 10,
      }}
    >
      <animated.div
        style={{
          transform: transform,
        }}
      >
        <StaticImage
          src="../../images/show-case.png"
          alt="Sails show case"
          placeholder="blurred"
          layout="constrained"
          width={1024}
          className="mx-auto shadow-2xl rounded-xl"
        />
      </animated.div>
    </div>
  );
}
